import React, { FunctionComponent } from 'react';
import FormHeader from '../../Form/FormHeader';
import styles from '../SSOForm/SSOForm.module.scss';
import { Link } from 'react-router-dom';
import { routes } from '../../../shared/constants';
import SSOFilterContainer from '../SSOFilter/SSOFilterContainer';
import { SSOProperty } from '../../../store/sso/types';
import hiltonImage from '../../../assets/SSOLogos/Hilton.png';

const ssoProperties: SSOProperty[] = [
  {
    id: 1,
    brand: 'Hilton SSO',
    imagePath: hiltonImage,
    redirect: 'https://users.kipsu.com/oauth/login?iss=https://fd.hilton.com',
  }
];

const SSOForm: FunctionComponent = () => {
  return (
    <form className={styles.form}>
      <FormHeader />
      <SSOFilterContainer properties={ssoProperties} />
      <footer className={styles.footer}>
        <Link to={routes.USERNAME_FORM} aria-label="Return to username form">
          <span className={styles.background} aria-hidden="true">
            {' '}
          </span>
          <span className={styles.link}>Back</span>
        </Link>
      </footer>
    </form>
  );
};

export default SSOForm;
